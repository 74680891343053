<router-outlet></router-outlet>

<footer class="tw-text-center tw-my-12">
  OIDCWarden Web<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    A modified version of the Bitwarden&reg; Web Vault for OIDCWarden (an unofficial rewrite of the
    Bitwarden&reg; server).<br />
    OIDCWarden is not associated with the Bitwarden&reg; project nor Bitwarden Inc.
  </div>
</footer>
