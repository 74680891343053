<form [formGroup]="formGroup" [bitSubmit]="submit" class="tw-container">
  <div *ngIf="loggingIn">
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    {{ "loading" | i18n }}
  </div>
  <div *ngIf="!loggingIn">
    <div class="tw-flex tw-gap-2">
      <button
        type="submit"
        bitButton
        bitFormButton
        buttonType="primary"
        [block]="true"
        appAutofocus
      >
        {{ "continue" | i18n }}
      </button>
    </div>
  </div>
</form>
